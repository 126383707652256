<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div
    v-else-if="campaign === null"
    class="text-center"
  >
    <h2>Campaign {{ $route.params.id }} not found</h2>
    <p>
      <router-link :to="{name: 'home', query}">
        Go Back
      </router-link>
    </p>
  </div>
  <div v-else>
    <div class="pb-3">
      <CampaignBreadcrumb :campaign="campaign" />
    </div>
    <TabMenu
      :model="items"
      :active-index="1"
    />
    <router-view
      :selected-profile="selectedProfile"
      :campaign="campaign"
    />
  </div>
</template>

<script>
import CampaignBreadcrumb from '../../../CampaignBreadcrumb.vue';
import A2CAClient from '../../../../api/a2caAuthorizedClient';

export default {
  components: { CampaignBreadcrumb },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      campaign: null,
      items: [
        {
          label: 'Ad Groups',
          icon: 'pi pi-fw pi-home',
          to: { name: 'adGroups', params: { id: this.$route.params.id }, query: this.query },
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    const client = new A2CAClient(this.query);
    const campaign = await client.getCampaign(
      this.selectedProfile.profileId,
      this.selectedProfile.region,
      this.$route.params.id,
    );

    // Campaign exists
    if (campaign.campaignId !== null) {
      this.campaign = campaign;
    }
    this.loading = false;
  },
};
</script>
